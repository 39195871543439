import { useAppContext } from "../context/state";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Heading from "../components/Heading";
import Button from "../snippets/Button";

import moment from "moment";
import news from "../lib/news.json";

function News() {
  const [appData] = useAppContext();

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow">
            <div className="w-full py-16">
              <Heading>
                {appData.translations.i18n.news.toLocaleUpperCase(
                  appData.translations.locale
                )}
              </Heading>
              <div className="w-full max-w-xl lg:max-w-6xl mx-auto lg:mb-12">
                {news[appData.lang]
                  .slice(0)
                  .reverse()
                  .map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col lg:flex-row items-center gap-8 mt-14 sm:mt-20"
                    >
                      <div
                        className={
                          "w-full lg:w-1/2 shrink-0" +
                          (index % 2 === 1 ? " lg:order-2" : "")
                        }
                      >
                        <img
                          src={item.image}
                          className="w-full h-auto"
                          alt={item.title}
                        />
                      </div>
                      <div className="flex-grow">
                        <div className="w-96 max-w-full mx-auto text-center">
                          <div className="font-heading text-4xl mb-1">
                            {item.title}
                          </div>
                          <div className="mb-2 text-gray-200">
                            {moment(item.date).format("DD/MM/YYYY")}
                          </div>
                          <div className="text-lg leading-relaxed text-gray-200 mb-4">
                            {item.excerpt}
                          </div>
                          <Link to={`/news/${item.id}`}>
                            <Button />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Container>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default News;
