import { useState, useMemo, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../context/state";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Heading from "../components/Heading";

import references from "../lib/references.json";
import Pagination from "../components/Pagination";

function References() {
  const [appData] = useAppContext();

  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { page: qPage } = useParams();

  useEffect(() => {
    if (qPage) {
      let p = parseInt(qPage);
      if (typeof p === "number" && !isNaN(p) && p > 0) {
        setPage(p);
      }
    }
  }, [qPage]);

  const pageInfo = useMemo(() => {
    let payload = { page };
    let itemsPerPage = appData.itemsPerPage.references;
    let totalPage = Math.ceil(references.length / itemsPerPage);
    if (page < 1 || page > totalPage) {
      payload.page = 1;
      setPage(payload.page);
    }
    let startIndex = (page - 1) * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;
    return {
      ...payload,
      nextPage: page === totalPage ? null : page + 1,
      prevPage: page === 1 ? null : page - 1,
      totalPage,
      itemsPerPage,
      startIndex,
      endIndex,
    };
  }, [page]);

  const handlePageChange = useCallback(
    (newPage) => {
      if (page === newPage) {
        return;
      }
      navigate(`/references/p/${newPage}`);
    },
    [page]
  );

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow">
            <div className="w-full py-16">
              <Heading>
                {appData.translations.i18n.references.toLocaleUpperCase(
                  appData.translations.locale
                )}
              </Heading>
              <div className="w-full max-w-6xl mx-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-5">
                {references
                  .slice(pageInfo.startIndex, pageInfo.endIndex)
                  .map((ref) => (
                    <a
                      href={ref.website}
                      rel="nofollow noreferrer"
                      target="_blank"
                      key={ref.name}
                      title={ref.name}
                      className="ref-item relative flex items-center justify-center border border-white border-opacity-25 hover:border-opacity-100 bg-white bg-opacity-5 hover:bg-opacity-100 cursor-pointer transition-all"
                    >
                      <img
                        src={ref.img_light}
                        className="ref-img-light w-1/2 h-auto py-10 transition-all"
                        alt={ref.name}
                      />
                      <img
                        src={ref.img_dark}
                        className="ref-img-dark absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-1/2 h-auto py-10 transition-all"
                        alt={ref.name}
                      />
                    </a>
                  ))}
              </div>
              <Pagination data={pageInfo} onChange={handlePageChange} />
            </div>
          </Container>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default References;
