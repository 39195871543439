import { useAppContext } from '../context/state';
import Header from "../components/Header";
import Footer from '../components/Footer';
import Container from '../components/Container';
import Heading from '../components/Heading';

import brands from '../lib/brands.json';

function Brands() {

  const [appData, ] = useAppContext();

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow">
            <div className="w-full py-16">
              <Heading>{appData.translations.i18n.our_brands.toLocaleUpperCase(appData.translations.locale)}</Heading>
              <div className="w-full max-w-5xl mx-auto">
                <div className="flex flex-col gap-8">
                  {brands.map((brand) => (
                  <div className="flex flex-col sm:flex-row items-center gap-8">
                    <div className="w-full sm:w-2/5 md:w-1/5 shrink-0 border border-white border-opacity-25 p-2">
                      <img src={brand.image} className="w-full h-auto" alt={brand.name} />
                    </div>
                    <div className="flex-grow">
                      <div className="font-heading text-4xl mb-4">
                        { brand.name }
                      </div>
                      <div className="mb-2 text-lg leading-relaxed">
                        { brand.description }
                      </div>
                    </div>
                  </div>
                  )) }
                </div>
              </div>
            </div>
          </Container>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Brands;