import { Link } from "react-router-dom";
import { useAppContext } from "../context/state";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Heading from "../components/Heading";

import categories from "../lib/productCategories.json";

function ProductCategory() {
  const [appData] = useAppContext();

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow">
            <div className="w-full py-16">
              <Heading>
                {appData.translations.i18n.product_categories.toLocaleUpperCase(
                  appData.translations.locale
                )}
              </Heading>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-x-5 gap-y-12 md:gap-x-5 md-gap-y-5">
                {categories.map((category) => (
                  <Link
                    to={`/products/${category.slug}`}
                    key={category.id}
                    className="cat-item block cursor-pointer"
                  >
                    <div className="relative flex transition-all overflow-hidden mb-4 aspect-2/3 bg-white bg-opacity-25">
                      <img
                        src={category.image}
                        className="cat-img relative z-20 w-full h-auto transition-all duration-1000"
                        alt={category.name[appData.lang]}
                      />
                      <div className="absolute w-full h-full z-10 flex items-center justify-center">
                        <svg
                          className="animate-spin h-8 w-8 text-darkblue"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="cat-name text-xl sm:text-3xl md:text-2xl lg:text-3xl font-heading text-center text-gray-400 px-4 xl:px-8 transition-colors">
                      {category.name[appData.lang].toLocaleUpperCase(
                        appData.translations.locale
                      )}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </Container>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default ProductCategory;
