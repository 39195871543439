import { useEffect, useState } from "react";
import { useAppContext } from "../context/state";
import { useNavigate, useParams, Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "../components/Container";

import moment from "moment";
import news from "../lib/news.json";

function NewsDetail() {
  const [appData] = useAppContext();
  const [newsItem, setNewsItem] = useState({});
  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let item = news[appData.lang].find((n) => n.id === parseInt(id));
    setNewsItem(item);
    if (!item) {
      navigate("/news");
    }
  }, [id, navigate, appData.lang]);

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow">
            <div className="w-full py-16">
              <div className="w-full max-w-5xl mx-auto">
                <div className="relative w-full h-96 mb-12 bg-black">
                  <div className="absolute z-10 inset-0 flex flex-col items-center justify-center">
                    <div className="font-heading text-4xl md:text-5xl px-8 mb-1 text-center">
                      {newsItem.title}
                    </div>
                    <div className="mb-2 text-gray-200">
                      {moment(newsItem.date).format("DD/MM/YYYY HH:mm")}
                    </div>
                    <Link
                      to="/news"
                      className="inline-block bg-black px-4 py-2 text-sm"
                    >
                      {appData.translations.i18n.news.toLocaleUpperCase(
                        appData.translations.locale
                      )}
                    </Link>
                  </div>
                  <img
                    src={newsItem.image}
                    className="absolute w-full h-full object-cover opacity-40"
                    alt={newsItem.title}
                  />
                </div>
                <div
                  className="max-w-4xl mx-auto mb-12 text-lg leading-relaxed text-center"
                  dangerouslySetInnerHTML={{ __html: newsItem.content }}
                />
              </div>
            </div>
          </Container>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default NewsDetail;
