import { useEffect, useRef } from "react";
import { useAppContext } from "../context/state";
import { Link, useLocation } from "react-router-dom";

import { BiCaretDown } from "react-icons/bi";
import { FaBars, FaTimes } from "react-icons/fa";

function Header(props) {
  const [appData, setAppData] = useAppContext();
  const dropdownRef = useRef(null);
  const loc = useLocation();

  const toggleMobileMenu = () => {
    setAppData((oldAppData) => ({
      ...oldAppData,
      mmActive: !oldAppData.mmActive,
    }));
  };

  const toggleDropdown = (ref) => {
    return () => {
      ref.current.classList[
        ref.current.classList.contains("open") ? "remove" : "add"
      ]("open");
    };
  };

  const changeLang = (lang) => {
    return () => {
      setAppData((oldAppData) => ({ ...oldAppData, lang }));
      localStorage.setItem("user_lang", lang);
      dropdownRef.current.classList.remove("open");
    };
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        (!(event instanceof KeyboardEvent) || event.key === "Escape") &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        (!event.target.classList ||
          !event.target.classList.contains("dropdown-toggle-btn"))
      ) {
        dropdownRef.current.classList.remove("open");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  let menuLinks = (
    <>
      <Link
        to="/about"
        className={
          "menu-link relative flex items-center md:px-4 lg:px-7 xl:px-12 2xl:px-14" +
          (loc.pathname.indexOf("/about") === 0 ? " menu-link-active" : "")
        }
      >
        {appData.translations.i18n.about.toLocaleUpperCase(
          appData.translations.locale
        )}
      </Link>
      <Link
        to="/references"
        className={
          "menu-link relative flex items-center md:px-4 lg:px-7 xl:px-12 2xl:px-14" +
          (loc.pathname.indexOf("/references") === 0 ? " menu-link-active" : "")
        }
      >
        {appData.translations.i18n.references.toLocaleUpperCase(
          appData.translations.locale
        )}
      </Link>
      <Link
        to="/products"
        className={
          "menu-link relative flex items-center md:px-4 lg:px-7 xl:px-12 2xl:px-14" +
          (loc.pathname.indexOf("/products") === 0 ? " menu-link-active" : "")
        }
      >
        {appData.translations.i18n.products.toLocaleUpperCase(
          appData.translations.locale
        )}
      </Link>
      <Link
        to="/news"
        className={
          "menu-link relative flex items-center md:px-4 lg:px-7 xl:px-12 2xl:px-14" +
          (loc.pathname.indexOf("/news") === 0 ? " menu-link-active" : "")
        }
      >
        {appData.translations.i18n.news.toLocaleUpperCase(
          appData.translations.locale
        )}
      </Link>
      <Link
        to="/catalog"
        className={
          "menu-link relative flex items-center md:px-4 lg:px-7 xl:px-12 2xl:px-14" +
          (loc.pathname.indexOf("/catalog") === 0 ? " menu-link-active" : "")
        }
      >
        {appData.translations.i18n.catalog.toLocaleUpperCase(
          appData.translations.locale
        )}
      </Link>
      <Link
        to="/contact"
        className={
          "menu-link relative flex items-center md:px-4 lg:px-7 xl:px-12 2xl:px-14" +
          (loc.pathname.indexOf("/contact") === 0 ? " menu-link-active" : "")
        }
      >
        {appData.translations.i18n.contact.toLocaleUpperCase(
          appData.translations.locale
        )}
      </Link>
    </>
  );

  return (
    <div
      className={
        "w-full border-b border-white border-opacity-25 font-heading text-xl fixed z-50" +
        (props.blur && props.blur === "none"
          ? " bg-darkblue"
          : " backdrop-blur-sm")
      }
    >
      <div className="flex items-stretch h-20">
        <div className="flex w-32 md:w-20 lg:w-24 shrink-0">
          <Link
            to="/"
            className="shrink-0 flex items-center justify-end md:justify-center w-full md:border-r border-white border-opacity-25"
          >
            <img
              src="/images/sencam-s.svg"
              className="hidden md:inline-block w-6 h-auto translate-y-1"
              alt="Şencam"
            />
            <img
              src="/images/sencam.svg"
              className="inline-block md:hidden w-24 h-auto translate-y-1"
              alt="Şencam"
            />
          </Link>
        </div>
        <div className="w-full flex-grow">
          <div className="main-menu hidden md:flex items-stretch justify-center h-full overflow-hidden">
            {menuLinks}
          </div>
        </div>
        <div className="flex w-48 md:w-20 lg:w-24 shrink-0">
          <div className="relative shrink-0 w-1/2 md:w-full md:border-l border-white border-opacity-25">
            <div
              className="dropdown-toggle-btn flex items-center justify-center cursor-pointer h-full"
              onClick={toggleDropdown(dropdownRef)}
            >
              {appData.translations.code}
              <BiCaretDown className="caret-down ml-2 text-base" />
            </div>
            <div
              id="header-lang-dropdown"
              className="dropdown-menu"
              ref={dropdownRef}
            >
              {appData.languages.map((lang) => (
                <div
                  key={lang.code}
                  className={
                    "dm-item" + (lang.code === appData.lang ? " active" : "")
                  }
                  onClick={changeLang(lang.code)}
                >
                  {lang.name.toUpperCase()}
                </div>
              ))}
            </div>
          </div>
          <div
            id="mobile-menu-button"
            className={
              (appData.mmActive ? "active " : "border-l ") +
              "mobile-menu-button relative z-30 overflow-hidden cursor-pointer select-none shrink-0 items-center justify-center border-white border-opacity-25 md:hidden flex w-1/2"
            }
            onClick={toggleMobileMenu}
          >
            <FaBars
              className={"text-2xl" + (appData.mmActive ? " hidden" : "")}
            />
            <FaTimes
              className={"text-2xl" + (appData.mmActive ? "" : " hidden")}
            />
          </div>
          <div
            className={
              (appData.mmActive ? "active " : "") +
              "mobile-menu absolute right-0 top-0 z-20 w-full max-w-md h-screen overflow-hidden"
            }
          >
            <div className="mobile-menu-inner w-full h-screen overflow-y-auto flex flex-col items-center justify-center gap-14 bg-black bg-opacity-90 border-l border-white border-opacity-25">
              {menuLinks}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
