import React from 'react';

function Container({ children, className }) {
  return (
    <div className={"w-full max-w-7xl px-8 xl:px-0 mx-auto" + (className ? " "+ className : "")}>
      {children}
    </div>
  )
}

export default Container