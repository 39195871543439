import { Link } from 'react-router-dom';
import { useAppContext } from '../context/state';

function ProductItem(props) {

  const [appData, ] = useAppContext();

  return (
    <Link to={`/products/${props.category.slug}/${props.product.id}`} className="prod-item block cursor-pointer">
      <div className="bg-white">
        <div className="relative w-full flex transition-all overflow-hidden aspect-square">
          <img src={props.product.images[0]} className="prod-img relative z-20 w-full h-auto transition-all duration-1000" alt={props.product.name[appData.lang]} />
          <div className="absolute w-full h-full z-10 flex items-center justify-center">
            <svg className="animate-spin h-8 w-8 text-darkblue" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          <div className="prod-name absolute z-30 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-2/3 py-6 bg-white bg-opacity-50 border border-darkblue text-center backdrop-blur-sm">
            {/* <div className="text-gray-600 tracking-widest">{props.product.name[appData.lang].toLocaleUpperCase(appData.translations.locale)}</div> */}
            <div className="text-darkblue font-heading text-xl sm:text-2xl xl:text-3xl flex items-center xl:gap-4 w-4/5 mx-auto">
              <hr className="w-full border-darkblue hidden xl:block" />
              <span className="whitespace-nowrap w-full text-center">{appData.translations.i18n.see_product_detail.toLocaleUpperCase(appData.translations.locale)}</span>
              <hr className="w-full border-darkblue hidden xl:block" />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ProductItem;