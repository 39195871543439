function Heading({ children, className }) {
  return (
    <div
      className={
        "page-heading mb-12 text-3xl sm:text-5xl lg:text-6xl" +
        (className ? " " + className : "")
      }
    >
      <div className="text-center whitespace-nowrap">{children}</div>
    </div>
  );
}

export default Heading;
