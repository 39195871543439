import { useRef, useState } from "react";
import Container from "../components/Container";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Heading from "../components/Heading";
import { useAppContext } from "../context/state";
import Button from "../snippets/Button";

import emailjs from "@emailjs/browser";
import { BiCheckCircle } from "react-icons/bi";

function Career() {
  const [appData] = useAppContext();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const form = useRef();

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    emailjs
      .sendForm(
        "service_fa25uob",
        "template_8oa1mfq",
        form.current,
        "mGGJL3zPuZN1WDaZS"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            setSubmitted(true);
          }
          setSubmitting(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow flex items-center justify-center">
            <div className="w-full py-16">
              <Heading>
                {appData.translations.i18n.career.toLocaleUpperCase(
                  appData.translations.locale
                )}
              </Heading>
              <div className="flex flex-col md:flex-row items-stretch w-full md:w-11/12 mx-auto border border-white border-opacity-25">
                <div className="relative w-full order-1 md:w-1/2 md:order-0 shrink-0 bg-white bg-opacity-5">
                  <form
                    className="block px-10 py-12"
                    ref={form}
                    encType="multipart/form-data"
                  >
                    <div className="mb-10 text-center text-3xl sm:text-4xl font-heading text-gray-400">
                      {appData.translations.i18n.career_subtitle.toLocaleUpperCase(
                        appData.translations.locale
                      )}
                    </div>
                    <div className="grid grid-cols-2 gap-6">
                      <input
                        type="text"
                        name="full_name"
                        placeholder={appData.translations.i18n.your_name.toLocaleUpperCase(
                          appData.translations.locale
                        )}
                        className="w-full h-14 px-4 border border-white border-opacity-50 focus:border-opacity-70 transition-all bg-transparent outline-none text-white placeholder:text-gray-500 font-body text-base"
                      />
                      <input
                        type="email"
                        name="email_address"
                        placeholder={appData.translations.i18n.your_email.toLocaleUpperCase(
                          appData.translations.locale
                        )}
                        className="w-full h-14 px-4 border border-white border-opacity-50 focus:border-opacity-70 transition-all bg-transparent outline-none text-white placeholder:text-gray-500 font-body text-base"
                      />
                      <input
                        type="tel"
                        name="phone"
                        placeholder={appData.translations.i18n.your_phone.toLocaleUpperCase(
                          appData.translations.locale
                        )}
                        className="w-full col-span-2 h-14 px-4 border border-white border-opacity-50 focus:border-opacity-70 transition-all bg-transparent outline-none text-white placeholder:text-gray-500 font-body text-base"
                      />
                      <textarea
                        name="message"
                        placeholder={appData.translations.i18n.your_message.toLocaleUpperCase(
                          appData.translations.locale
                        )}
                        className="w-full col-span-2 h-32 p-4 border border-white border-opacity-50 focus:border-opacity-70 transition-all bg-transparent outline-none text-white placeholder:text-gray-500 font-body text-base resize-none"
                      ></textarea>

                      <div className="w-full col-span-2 flex items-center gap-6">
                        <div className="font-heading text-gray-500 text-lg tracking-widest">
                          {appData.translations.i18n.your_cv.toLocaleUpperCase(
                            appData.translations.locale
                          )}
                        </div>
                        <label className="block">
                          <span className="sr-only">Choose CV File</span>
                          <input
                            type="file"
                            name="cv_file"
                            className="block cursor-pointer file:cursor-pointer w-full font-heading text-base text-gray-500
                              file:mr-4 file:py-3 file:px-4 file:border file:border-gray-300 file:border-opacity-50 file:hover:border-gray-100
                              file:shadow-none file:outline-none
                              file:text-base file:tracking-widest file:uppercase
                              file:bg-transparent file:text-gray-500 "
                          />
                        </label>
                      </div>
                      <div className="w-full col-span-2 text-center mt-6 opacity-50 hover:opacity-70 transition-opacity">
                        <Button
                          text={appData.translations.i18n.send}
                          onClick={handleFormSubmit}
                          loading={submitting}
                        />
                      </div>
                    </div>
                  </form>
                  {submitted ? (
                    <div className="absolute z-20 inset-0 bg-darkblue">
                      <div className="absolute inset-0 bg-white bg-opacity-5 flex flex-col items-center justify-center">
                        <div className="mb-2">
                          <BiCheckCircle size="72" />
                        </div>
                        <div className="w-2/3 text-center">
                          {appData.translations.i18n.contact_success}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="w-full md:w-1/2 h-96 md:h-auto bg-black relative">
                  <img
                    src="/images/career.jpg"
                    className="absolute left-0 top-0 w-full h-full object-cover select-none opacity-80"
                    alt={appData.translations.i18n.sencam_catalog.toLocaleUpperCase(
                      appData.translations.locale
                    )}
                  />
                </div>
              </div>
            </div>
          </Container>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Career;
