import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAppContext } from "../context/state";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "../components/Container";
import ProductItem from "../snippets/ProductItem";

import categories from "../lib/productCategories.json";
import products from "../lib/products.json";

import { BiChevronRight } from "react-icons/bi";

function Products() {
  const [appData] = useAppContext();
  const navigate = useNavigate();

  const [filteredProducts, setFilteredProducts] = useState([]);
  const { cat } = useParams();
  const category = categories.find((c) => c.slug === cat);

  useEffect(() => {
    if (!category) {
      navigate("/products");
    } else {
      let payload = [];
      for (let i in products) {
        if (category.id === products[i].cat_id) {
          payload.push(products[i]);
        }
      }
      setFilteredProducts(payload);
    }
  }, [category]);

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow">
            <div className="w-full py-16">
              <div className="flex flex-wrap items-center gap-2 mb-12 text-2xl md:text-3xl font-heading">
                <div>
                  <Link
                    to="/products"
                    className="hover:underline underline-offset-2"
                  >
                    {appData.translations.i18n.product_categories.toLocaleUpperCase(
                      appData.translations.locale
                    )}
                  </Link>
                </div>
                <div>
                  <BiChevronRight />
                </div>
                <div className="text-gray-400">
                  {category.name[appData.lang].toLocaleUpperCase(
                    appData.translations.locale
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                {filteredProducts.map((p, index) => (
                  <ProductItem key={index} product={p} category={category} />
                ))}
              </div>
            </div>
          </Container>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Products;
