import { useRef, useState } from "react";
import Container from "../components/Container";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Heading from "../components/Heading";
import { useAppContext } from "../context/state";
import Button from "../snippets/Button";

import { BsFillTelephoneFill } from "react-icons/bs";
import { FaCompass, FaEnvelope, FaFax } from "react-icons/fa";
import { MdFactory } from "react-icons/md";

import emailjs from "@emailjs/browser";
import { BiCheckCircle } from "react-icons/bi";

function Contact() {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [appData] = useAppContext();
  const form = useRef();

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    emailjs
      .sendForm(
        "service_fa25uob",
        "template_aqh0h09",
        form.current,
        "mGGJL3zPuZN1WDaZS"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            setSubmitted(true);
          }
          setSubmitting(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow flex items-center justify-center">
            <div className="w-full py-16">
              <Heading>
                {appData.translations.i18n.contact.toLocaleUpperCase(
                  appData.translations.locale
                )}
              </Heading>
              <div className="flex flex-col md:flex-row items-stretch w-full md:w-11/12 mx-auto border border-white border-opacity-25">
                <div className="relative w-full order-1 md:w-1/2 md:order-0 shrink-0 bg-white bg-opacity-5">
                  <form className="block px-10 py-12" ref={form}>
                    <div className="mb-10 text-center text-3xl sm:text-4xl font-heading text-gray-400">
                      {appData.translations.i18n.get_in_touch.toLocaleUpperCase(
                        appData.translations.locale
                      )}
                    </div>
                    <div className="flex flex-col sm:flex-row items-center gap-6">
                      <input
                        type="text"
                        name="full_name"
                        placeholder={appData.translations.i18n.your_name.toLocaleUpperCase(
                          appData.translations.locale
                        )}
                        className="w-full h-14 px-4 border border-white border-opacity-50 focus:border-opacity-70 transition-all bg-transparent outline-none text-white placeholder:text-gray-500 font-body text-base"
                      />
                      <input
                        type="email"
                        name="email_address"
                        placeholder={appData.translations.i18n.your_email.toLocaleUpperCase(
                          appData.translations.locale
                        )}
                        className="w-full h-14 px-4 border border-white border-opacity-50 focus:border-opacity-70 transition-all bg-transparent outline-none text-white placeholder:text-gray-500 font-body text-base"
                      />
                    </div>
                    <div className="mt-6">
                      <textarea
                        name="message"
                        placeholder={appData.translations.i18n.your_message.toLocaleUpperCase(
                          appData.translations.locale
                        )}
                        className="w-full h-32 p-4 border border-white border-opacity-50 focus:border-opacity-70 transition-all bg-transparent outline-none text-white placeholder:text-gray-500 font-body text-base resize-none"
                      ></textarea>
                    </div>
                    <div className="text-center mt-6 opacity-50 hover:opacity-70 transition-opacity">
                      <Button
                        text={appData.translations.i18n.send}
                        onClick={handleFormSubmit}
                        loading={submitting}
                      />
                    </div>
                  </form>
                  {submitted ? (
                    <div className="absolute z-20 inset-0 bg-darkblue">
                      <div className="absolute inset-0 bg-white bg-opacity-5 flex flex-col items-center justify-center">
                        <div className="mb-2">
                          <BiCheckCircle size="72" />
                        </div>
                        <div className="w-2/3 text-center">
                          {appData.translations.i18n.contact_success}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="w-full order-0 md:w-1/2 md-order-1 shrink-0 flex items-center justify-center px-6 md:px-0 border-b md:border-none border-white border-opacity-25">
                  <div className="flex flex-col gap-6 sm:gap-10 w-full max-w-xs py-16 text-xl sm:text-2xl">
                    <div className="flex">
                      <MdFactory className="mr-4 mt-2 shrink-0" />{" "}
                      {appData.translations.i18n.company_name}
                    </div>
                    <div className="flex">
                      <BsFillTelephoneFill className="mr-4 mt-2 shrink-0" />{" "}
                      {appData.translations.i18n.company_phone}
                    </div>
                    <div className="flex">
                      <FaFax className="mr-4 mt-2 shrink-0" />{" "}
                      {appData.translations.i18n.company_fax}
                    </div>
                    <div className="flex">
                      <FaEnvelope className="mr-4 mt-2 shrink-0" />{" "}
                      {appData.translations.i18n.company_email}
                    </div>
                    <div className="leading-relaxed">
                      <a
                        href="https://goo.gl/maps/yYcUq6NoDBwH5awHA"
                        target="_blank"
                        rel="noreferrer"
                        className="flex"
                      >
                        <FaCompass className="mr-4 mt-2 shrink-0" />{" "}
                        {appData.translations.i18n.company_address}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Contact;
