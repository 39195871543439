import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAppContext } from "../context/state";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Button from "../snippets/Button";
import ProductItem from "../snippets/ProductItem";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import categories from "../lib/productCategories.json";
import products from "../lib/products.json";

import { BiChevronRight, BiBarcodeReader } from "react-icons/bi";
import { HiOutlineQrcode } from "react-icons/hi";
import {
  GiWashingMachine,
  GiWineGlass,
  GiWeight,
  GiStorkDelivery,
} from "react-icons/gi";
import { GoPackage } from "react-icons/go";
import { IoIosResize } from "react-icons/io";
import { BsBoxSeam } from "react-icons/bs";

import emailjs from "@emailjs/browser";
import { BiCheckCircle } from "react-icons/bi";

function Product() {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [appData] = useAppContext();
  const form = useRef();
  const [currentProdImgIndex, setCurrentProdImgIndex] = useState(0);
  const [product, setProduct] = useState();
  const [category, setCategory] = useState();
  const [catProducts, setCatProducts] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const navigate = useNavigate();

  let { cat, id } = useParams();

  const iconsMap = useMemo(() => {
    return {
      barcode: <BiBarcodeReader />,
      sku: <HiOutlineQrcode />,
      model: <GiWineGlass />,
      weight: <GiWeight />,
      package_sizes: <GoPackage />,
      sizes: <IoIosResize />,
      qty_in_package: <BsBoxSeam />,
      cleaning_instructions: <GiWashingMachine />,
      delivery_time: <GiStorkDelivery />,
    };
  }, []);

  useEffect(() => {
    let findCat = categories.find((c) => c.slug === cat);
    setCategory(findCat);
    if (!findCat) {
      navigate("/products");
    }
  }, [cat, navigate]);

  useEffect(() => {
    setCurrentProdImgIndex(0);
    let findProd = products.find((p) => p.id === parseInt(id));
    setProduct(findProd);
    if (!findProd) {
      navigate("/products");
    }
  }, [id, navigate]);

  useEffect(() => {
    if (product) {
      // Find product and other products in same category
      let catProductsPayload = [];
      let productIndex = products.findIndex((p) => p.id === product.id);

      for (let i in products) {
        if (i > productIndex && catProductsPayload.length < 4) {
          if (products[i].cat_id === product.cat_id) {
            catProductsPayload.push(products[i]);
          }
        }
      }

      for (let i in products) {
        if (catProductsPayload.length < 4) {
          if (products[i].cat_id === product.cat_id && productIndex !== i) {
            catProductsPayload.push(products[i]);
          }
        } else {
          break;
        }
      }

      setCatProducts(catProductsPayload);
    }
  }, [product]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    emailjs
      .sendForm(
        "service_fa25uob",
        "template_pf2j19f",
        form.current,
        "mGGJL3zPuZN1WDaZS"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            setSubmitted(true);
          }
          setSubmitting(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const changeProdImg = useCallback(
    (index) => {
      return () => {
        document
          .getElementById(`prod-thumb-${currentProdImgIndex}`)
          .classList.remove("active");
        setCurrentProdImgIndex(index);
        document.getElementById(`prod-thumb-${index}`).classList.add("active");
      };
    },
    [currentProdImgIndex]
  );

  if (!category || !product) {
    return null;
  }

  const lightboxMarkup = lightboxOpen ? (
    <Lightbox
      mainSrc={product.images[currentProdImgIndex]}
      nextSrc={
        product.images[(currentProdImgIndex + 1) % product.images.length]
      }
      prevSrc={
        product.images[
          (currentProdImgIndex + product.images.length - 1) %
            product.images.length
        ]
      }
      onCloseRequest={() => setLightboxOpen(false)}
      onMovePrevRequest={() =>
        setCurrentProdImgIndex(
          (currentProdImgIndex + product.images.length - 1) %
            product.images.length
        )
      }
      onMoveNextRequest={() =>
        setCurrentProdImgIndex(
          (currentProdImgIndex + product.images.length + 1) %
            product.images.length
        )
      }
    />
  ) : null;

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow">
            <div className="w-full py-16">
              {/* Breadcrumb */}
              <div className="flex flex-wrap items-center gap-2 text-2xl md:text-3xl mb-12 font-heading">
                <div className="">
                  <Link
                    to="/products"
                    className="hover:underline underline-offset-2"
                  >
                    {appData.translations.i18n.product_categories.toLocaleUpperCase(
                      appData.translations.locale
                    )}
                  </Link>
                </div>
                <div className="">
                  <BiChevronRight />
                </div>
                <div className="">
                  <Link
                    to={`/products/${category.slug}`}
                    className="hover:underline underline-offset-2"
                  >
                    {category.name[appData.lang].toLocaleUpperCase(
                      appData.translations.locale
                    )}
                  </Link>
                </div>
              </div>

              {/* Main Product Block */}
              <div className="flex flex-col md:flex-row items-start gap-8">
                {/* Product Image */}
                <div className="flex flex-col lg:flex-row items-start gap-4 lg:gap-8 flex-grow">
                  <div className="w-full lg:w-20 shrink-0 flex flex-wrap lg:flex-nowrap lg:flex-col items-center gap-4 order-1 lg-order-0">
                    {product.images.map((img, index) => (
                      <div
                        key={index}
                        id={`prod-thumb-${index}`}
                        className={
                          "prod-thumb w-1/5 lg:w-full aspect-square overflow-hidden cursor-pointer bg-white bg-opacity-5 border-2 border-white" +
                          (index === currentProdImgIndex ? " active" : "")
                        }
                        onClick={changeProdImg(index)}
                      >
                        <img
                          src={img}
                          className="w-full h-auto"
                          alt={product.name[appData.lang]}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="relative w-full aspect-square flex-grow order-0 lg:order-1">
                    <img
                      src={product.images[currentProdImgIndex]}
                      className="relative z-20 w-full h-auto cursor-pointer"
                      alt={product.name}
                      onClick={() => setLightboxOpen(true)}
                    />
                    <div className="absolute z-10 inset-0 flex items-center justify-center bg-white bg-opacity-5 border border-white border-opacity-25">
                      <svg
                        className="animate-spin h-8 w-8 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>

                {/* Product Description & Request Form */}
                <div className="w-full md:w-2/5 lg:w-1/3 shrink-0">
                  <div className="text-xl font-semibold mb-2">
                    {product.name[appData.lang].toLocaleUpperCase(
                      appData.translations.locale
                    )}
                  </div>
                  <div className="leading-relaxed mb-4">
                    {product.desc[appData.lang]}
                  </div>
                  <div className="mb-8">
                    {Object.keys(product.properties[appData.lang]).map(
                      (prop, index) => (
                        <div
                          className={
                            "flex items-center border-b border-white border-opacity-25" +
                            (index === 0
                              ? " border-t border-white border-opacity-25"
                              : "")
                          }
                        >
                          <div className="shrink-0 w-1/2 py-2 flex items-center gap-2 font-regular">
                            {iconsMap[prop] || ""}{" "}
                            {appData.translations.i18n[prop]}:
                          </div>
                          <div className="w-1/2">
                            {product.properties[appData.lang][prop]}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="relative bg-white bg-opacity-5 border border-white border-opacity-25">
                    <form className="block py-10 px-10" ref={form}>
                      <div className="mb-1 text-center text-4xl font-heading">
                        {appData.translations.i18n.product_form_title.toLocaleUpperCase(
                          appData.translations.locale
                        )}
                      </div>
                      <div className="mb-6 text-center text-gray-400">
                        {appData.translations.i18n.product_form_desc}
                      </div>
                      <div className="mt-6">
                        <input
                          type="text"
                          name="full_name"
                          placeholder={appData.translations.i18n.your_name.toLocaleUpperCase(
                            appData.translations.locale
                          )}
                          className="w-full h-14 px-4 border border-white border-opacity-50 focus:border-opacity-70 transition-all bg-transparent outline-none text-white placeholder:text-gray-500 font-body text-base"
                        />
                      </div>
                      <div className="mt-6">
                        <input
                          type="tel"
                          name="phone_number"
                          placeholder={appData.translations.i18n.your_phone.toLocaleUpperCase(
                            appData.translations.locale
                          )}
                          className="w-full h-14 px-4 border border-white border-opacity-50 focus:border-opacity-70 transition-all bg-transparent outline-none text-white placeholder:text-gray-500 font-body text-base"
                        />
                      </div>
                      <div className="mt-6">
                        <textarea
                          name="message"
                          placeholder={appData.translations.i18n.your_message.toLocaleUpperCase(
                            appData.translations.locale
                          )}
                          className="w-full h-32 p-4 border border-white border-opacity-50 focus:border-opacity-70 transition-all bg-transparent outline-none text-white placeholder:text-gray-500 font-body text-base resize-none"
                        ></textarea>
                      </div>
                      <div className="text-center mt-6 opacity-50 hover:opacity-70 transition-opacity">
                        <input
                          type="hidden"
                          name="product_url"
                          value={`https://sencamsanayi.com/products/${category.slug}/${product.id}`}
                        />
                        <Button
                          text={appData.translations.i18n.send}
                          onClick={handleFormSubmit}
                          loading={submitting}
                        />
                      </div>
                    </form>
                    {submitted ? (
                      <div className="absolute z-20 inset-0 bg-darkblue">
                        <div className="absolute inset-0 bg-white bg-opacity-5 flex flex-col items-center justify-center">
                          <div className="mb-2">
                            <BiCheckCircle size="72" />
                          </div>
                          <div className="w-2/3 text-center">
                            {appData.translations.i18n.contact_success}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* Similar Products */}
              <div className={"mt-16" + (catProducts.length ? "" : " hidden")}>
                <div className="page-heading mb-12 text-xl sm:text-2xl lg:text-3xl font-heading">
                  <div className="text-center whitespace-nowrap">
                    {appData.translations.i18n.similar_products.toLocaleUpperCase(
                      appData.translations.locale
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                  {catProducts.map((p) => (
                    <ProductItem key={p.id} product={p} category={category} />
                  ))}
                </div>
              </div>
            </div>
          </Container>
          <Footer />
        </div>
        {lightboxMarkup}
      </div>
    </>
  );
}

export default Product;
