import React from "react";
import { FaSpinner } from "react-icons/fa";

import { FiChevronRight } from "react-icons/fi";

function Button({ text, loading, onClick }) {
  let innerText = false;
  if (text) {
    innerText = (
      <div className="border-r border-white flex-grow py-3 px-6 min-w-[160px] font-heading tracking-widest">
        {text.toLocaleUpperCase()}
      </div>
    );
  }

  if (loading) {
    innerText = (
      <div className="flex items-center justify-center px-8 py-3.5">
        <FaSpinner size="24" className="animate-spin" />
      </div>
    );
  }

  return (
    <div
      className={
        "btn inline-flex items-stretch border border-white text-lg select-none transition-all" +
        (loading ? "" : " cursor-pointer")
      }
      onClick={onClick && !loading ? onClick : () => {}}
    >
      {innerText}
      <div className="flex items-center shrink-0 px-4 py-3">
        <FiChevronRight size="24" className="animate-rarrow" />
      </div>
    </div>
  );
}

export default Button;
