import { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAppContext } from "../context/state";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import categories from "../lib/galleryCategories.json";
import gallery from "../lib/gallery.json";

import { BiChevronRight } from "react-icons/bi";
import Pagination from "../components/Pagination";

function Gallery() {
  const [appData] = useAppContext();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [filteredGallery, setFilteredGallery] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);

  const { cat: qCatSlug, page: qPage } = useParams();
  const category = categories.find((c) => c.slug === qCatSlug);

  useEffect(() => {
    if (qPage) {
      let p = parseInt(qPage);
      if (typeof p === "number" && !isNaN(p) && p > 0) {
        setPage(p);
      }
    }
  }, [qPage]);

  useEffect(() => {
    if (!category) {
      navigate("/gallery");
    } else {
      let payload = [];
      for (let i in gallery) {
        if (category.id === gallery[i].cat_id) {
          payload.push(gallery[i]);
        }
      }
      setFilteredGallery(payload);
    }
  }, [category]);

  const pageInfo = useMemo(() => {
    let payload = { page };
    let itemsPerPage = appData.itemsPerPage.gallery;
    let totalPage = Math.ceil(filteredGallery.length / itemsPerPage);
    if (page < 1 || page > totalPage) {
      payload.page = 1;
      setPage(payload.page);
    }
    let startIndex = (page - 1) * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;
    return {
      ...payload,
      nextPage: page === totalPage ? null : page + 1,
      prevPage: page === 1 ? null : page - 1,
      totalPage,
      itemsPerPage,
      startIndex,
      endIndex,
    };
  }, [page, filteredGallery]);

  const handlePageChange = useCallback(
    (newPage) => {
      if (page === newPage) {
        return;
      }
      navigate(`/gallery/${qCatSlug}/p/${newPage}`);
    },
    [page, qCatSlug]
  );

  const lightboxMarkup = lightboxOpen ? (
    <Lightbox
      mainSrc={filteredGallery[imgIndex].image_large}
      nextSrc={
        filteredGallery[(imgIndex + 1) % filteredGallery.length].image_large
      }
      prevSrc={
        filteredGallery[
          (imgIndex + filteredGallery.length - 1) % filteredGallery.length
        ].image_large
      }
      onCloseRequest={() => setLightboxOpen(false)}
      onMovePrevRequest={() =>
        setImgIndex(
          (imgIndex + filteredGallery.length - 1) % filteredGallery.length
        )
      }
      onMoveNextRequest={() =>
        setImgIndex(
          (imgIndex + filteredGallery.length + 1) % filteredGallery.length
        )
      }
    />
  ) : null;

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow">
            <div className="w-full py-16">
              <div className="flex flex-wrap items-center gap-2 mb-12 text-2xl md:text-3xl font-heading">
                <div>
                  <Link
                    to="/gallery"
                    className="hover:underline underline-offset-2"
                  >
                    {appData.translations.i18n.gallery.toLocaleUpperCase(
                      appData.translations.locale
                    )}
                  </Link>
                </div>
                <div>
                  <BiChevronRight />
                </div>
                <div className="text-gray-400">
                  {category.name[appData.lang].toLocaleUpperCase(
                    appData.translations.locale
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                {filteredGallery
                  .slice(pageInfo.startIndex, pageInfo.endIndex)
                  .map((item, index) => (
                    <div
                      onClick={() => {
                        setImgIndex(index);
                        setLightboxOpen(true);
                      }}
                      rel="nofollow noreferrer"
                      target="_blank"
                      key={item.id}
                      className="cursor-pointer"
                    >
                      <img
                        src={item.image}
                        className="w-full h-auto"
                        alt={item.name[appData.lang]}
                      />
                    </div>
                  ))}
              </div>
              <Pagination data={pageInfo} onChange={handlePageChange} />
              {lightboxMarkup}
            </div>
          </Container>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Gallery;
