import { useAppContext } from "../context/state";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Heading from "../components/Heading";

import team from "../lib/team.json";

function Team() {
  const [appData] = useAppContext();

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow">
            <div className="w-full py-16">
              <Heading>
                {appData.translations.i18n.our_team.toLocaleUpperCase(
                  appData.translations.locale
                )}
              </Heading>
              <div className="relative w-60 mx-auto px-4 pt-8 border border-white border-opacity-25 bg-white bg-opacity-5 mb-10">
                <img
                  src={team[0].image}
                  className="w-full h-auto z-10"
                  alt={team[0].name}
                />
                <div className="absolute z-20 -bottom-[1px] -left-[1px] -right-[1px] h-full bg-gradient-to-b from-darkblue/0 to-darkblue"></div>
                <div className="absolute w-full z-30 bottom-0 sm:bottom-auto sm:top-1/2 left-1/2 sm:left-auto sm:right-0 sm:-translate-y-1/2 -translate-x-1/2 sm:translate-x-1/2 text-center">
                  <div className="text-gray-300 tracking-widest text-lg -mb-1">
                    {team[0].title[appData.lang].toLocaleUpperCase(
                      appData.translations.locale
                    )}
                  </div>
                  <div className="text-white font-heading text-3xl sm:text-4xl">
                    {team[0].name}
                  </div>
                </div>
              </div>
              <div className="w-full max-w-5xl mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-12">
                {team.slice(1).map((member) => (
                  <div>
                    <div
                      key={member.name}
                      className="relative px-4 pt-4 border border-white border-opacity-25 bg-white bg-opacity-5"
                    >
                      <img
                        src={member.image}
                        className="w-full h-auto z-10"
                        alt={member.name}
                      />
                      <div className="absolute z-20 -bottom-[1px] -left-[1px] -right-[1px] h-full bg-gradient-to-b from-darkblue/0 to-darkblue"></div>
                    </div>
                    <div className="mt-3 text-center">
                      <div className="text-gray-300 tracking-widest text-sm -mb-1">
                        {member.title[appData.lang].toLocaleUpperCase(
                          appData.translations.locale
                        )}
                      </div>
                      <div className="text-white font-heading text-2xl sm:text-3xl">
                        {member.name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Container>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Team;
