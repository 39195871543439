import { useAppContext } from "../context/state";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Button from "../snippets/Button";

import BackgroundSlider from "react-background-slider";
import sliderImg1 from "../assets/images/slides/1.jpg";
import sliderImg2 from "../assets/images/slides/2.jpg";
import sliderImg3 from "../assets/images/slides/3.jpg";
import sliderImg4 from "../assets/images/slides/4.jpg";
import sliderImg5 from "../assets/images/slides/5.jpg";

function Home() {
  const [appData] = useAppContext();

  return (
    <>
      <Header />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow flex items-center justify-center">
            <div className="w-5/6 md:w-3/5 mx-auto py-16 md:py-24 mt-16">
              <img
                src="/images/sencam.svg"
                className="relative z-10 w-full h-auto mx-auto select-none"
                alt="Şencam"
              />
              <div className="relative z-20 w-full mt-6 md:mt-0 sm:w-4/5 mx-auto lg:-mt-16 lg:translate-x-10 text-center text-lg leading-relaxed">
                {appData.translations.i18n.promo_text}
              </div>
              <div className="text-center mt-12">
                <Link to="/about">
                  <Button text={appData.translations.i18n.about} />
                </Link>
              </div>
            </div>
          </Container>
          <Footer />
        </div>
        <div className="absolute w-full h-full top-0 left-0 z-10">
          {/* <img
            src="/images/promo-bg.png"
            className="w-full h-full object-cover xl:object-contain select-none"
            alt="Şencam"
          /> */}
          <BackgroundSlider
            images={[
              sliderImg2,
              sliderImg5,
              sliderImg3,
              sliderImg1,
              sliderImg4,
            ]}
            duration={10}
            transition={2}
          />
        </div>
      </div>
    </>
  );
}

export default Home;
