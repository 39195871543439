import { createContext, useState, useContext } from 'react';

import defaultAppState from '../lib/defaultAppState.json';
import translations from '../lib/translations.json';

//create a context, with createContext api
export const AppContext = createContext();

export function AppWrapper({ children }) {
    
    const [appData, setAppData] = useState(defaultAppState);
    let user_lang = localStorage.getItem("user_lang");

    if(user_lang && translations[user_lang] && user_lang !== appData.lang) {

      setAppData(oldAppData => ({...oldAppData, lang: user_lang}));
    
    } else if(!appData.translations || appData.translations.code !== appData.lang) {

      setAppData(oldAppData => ({...oldAppData, lang: translations[appData.lang].code, translations: translations[appData.lang]}));
      document.documentElement.setAttribute("lang", appData.lang.toLowerCase());

      if(!appData.languages) {
        let languages = [];
        for(let i in translations) {
          languages.push({code: translations[i].code, name: translations[i].name});
        }
        setAppData(oldAppData => ({...oldAppData, languages}));
      }
          
    }

    return (
      <AppContext.Provider value={[appData, setAppData]}>
        {children}
      </AppContext.Provider>
    );
};

export function useAppContext() {
  return useContext(AppContext);
}