function Pagination(props) {
  const { data, onChange } = props;

  if (data.totalPage <= 1) {
    return null;
  }

  return (
    <div className="pagination">
      <div
        className={"item" + (data.prevPage ? "" : " disabled")}
        onClick={() => onChange(data.prevPage || 1)}
      >
        &larr;
      </div>
      {[...Array(data.totalPage).keys()].map((k) => (
        <div
          className={"item" + (data.page === k + 1 ? " active" : "")}
          key={k}
          onClick={() => onChange(k + 1)}
        >
          {k + 1}
        </div>
      ))}
      <div
        className={"item" + (data.nextPage ? "" : " disabled")}
        onClick={() => onChange(data.nextPage || data.totalPage)}
      >
        &rarr;
      </div>
    </div>
  );
}

export default Pagination;
