import { useEffect } from 'react';
import { useAppContext } from '../context/state';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {

  const [, setAppData] = useAppContext();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0
    });
    setAppData(oldAppData => ({...oldAppData, mmActive: false}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
}

export default ScrollToTop;