import { useAppContext } from "../context/state";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Heading from "../components/Heading";

function About() {
  const [appData] = useAppContext();

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow">
            <div className="w-full py-16">
              <Heading>
                {appData.translations.i18n.about.toLocaleUpperCase(
                  appData.translations.locale
                )}
              </Heading>
              <div className="w-full max-w-5xl mx-auto text-lg leading-relaxed text-center">
                <div
                  className="mb-12"
                  dangerouslySetInnerHTML={{
                    __html: appData.translations.i18n.about_section_1,
                  }}
                />
                <div className="mb-12">
                  <img
                    src="/images/about-1.png"
                    className="w-full max-w-3xl mx-auto"
                    alt="Şencam"
                  />
                </div>
                <div
                  className="mb-12"
                  dangerouslySetInnerHTML={{
                    __html: appData.translations.i18n.about_section_2,
                  }}
                />
                <div className="mb-12">
                  <img
                    src="/images/about-2.png"
                    className="w-full max-w-3xl mx-auto"
                    alt="Şencam"
                  />
                </div>
                <div
                  className="mb-12"
                  dangerouslySetInnerHTML={{
                    __html: appData.translations.i18n.about_section_3,
                  }}
                />
              </div>
            </div>
          </Container>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default About;
